import { Store } from "bernie-plugin-mobx";
import { SerializedData } from "bernie-core";
import { Logger } from "bernie-logger";
import { Filter, Options } from "components/flexComponents/PropertyFilters/typings";
import { HotelSearchLinkBuilder } from "components/utility/HotelSearchLinkBuilder/HotelSearchLinkBuilder";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { PROPERTY_FILTERS_COMPONENT_GROUP_MAP } from "components/shared/PropertyFilterComponents/map/PropertyFiltersComponentGroupMap";
import { RadioButtonGroup } from "components/shared/PropertyFilterComponents/Groupings/RadioButtonGroup";
import { SelectGroup } from "components/shared/PropertyFilterComponents/Groupings/SelectGroup";
import { action, observable, makeObservable } from "mobx";
import { PropertyFilterPublisher } from "./PropertyFilterPublisher";
import {
  PropertyFiltersQueryQuery,
  PropertyFilterSearchQueryQueryVariables,
  PropertySearchFiltersInput,
  PropertySort,
  NumberValueInput,
  SelectedValueInput,
  RangeValueInput,
  FilterOptionRange,
} from "__generated__/typedefs";

const DEFAULT_SORT: PropertySort = "RECOMMENDED";
const validFilterNames = Object.keys(PROPERTY_FILTERS_COMPONENT_GROUP_MAP);

export type SelectedFilters = {
  name: string;
  option: Options;
};

function getFilterName(name: string) {
  switch (name) {
    case "star":
      return "starList";
    case "guestRating":
      return "reviewScore";
    case "lodging":
      return "structureTypes";
    case "regionId":
      return "neighborhood";
    default:
      return name;
  }
}

/**
 * Class that describes the state of the filters. This will be used as the source of truth.
 *
 * filterName refers to name of the "category" (e.g. Property Class)
 * optionName refers to the individual options for a filter (e.g. 5 stars)
 */
export class PropertyFiltersStore extends Store {
  // e.g. ordered map of key = "propertyClass", value = map of 1 star, 2 star, etc
  private publisher = new PropertyFilterPublisher();

  public order = 1;
  public filtersMap = new Map<string, Filter>();
  public originalRegionId: string;
  public frozenFilter: string[];
  public hasSort: boolean;
  public pricePill?: Options | null;
  public hasInteractedWithFilters: boolean = false;

  public constructor(state: SerializedData, logger: Logger) {
    super(state, logger);

    makeObservable(this, {
      order: observable,
      filtersMap: observable,
      originalRegionId: observable,
      frozenFilter: observable,
      hasSort: observable,
      pricePill: observable,
      hasInteractedWithFilters: observable,
      frezze: action,
      defrost: action,
      resetFilters: action,
      setFilters: action,
      syncFilters: action,
      setRegion: action,
      setPricePill: action,
      setHasSort: action,
      setHasInteractedWithFilters: action,
      setFilterRange: action,
      toggleSelection: action,
      setFilterStatus: action,
      setDisabled: action,
      resetPerCategory: action,
      resetAllFilters: action,
      clearFilters: action,
    });
  }

  public hydrate(data: SerializedData): void {
    const filtersMap = new Map(
      (data.filtersMap as any[]).map(([k, f]) => {
        return [k, { ...f, options: new Map(f.options) }];
      })
    );

    Object.assign(this, { ...data, filtersMap });
    this.syncFilters();
  }

  public toJSON() {
    /**
     * We are parsing the Map as a Tuple to prevent order changes that happend if we send it as a JSON
     */
    const filters = Array.from(this.filtersMap.keys()).map((filtersMapKey) => {
      const options = Array.from(this.filtersMap.get(filtersMapKey)!.options).map(([optionKey, option]) => {
        return [optionKey, option];
      });

      return [filtersMapKey, { ...this.filtersMap.get(filtersMapKey), options }];
    });

    return {
      filtersMap: filters,
      originalRegionId: String(this.originalRegionId),
    };
  }

  public frezze() {
    this.frozenFilter = Array.from(this.filtersMap.values()).reduce<string[]>((selectedFilters, filter) => {
      const options = Array.from(filter.options.values())
        .filter((option) => option.isSelected)
        .map((option) => option.selectedLabel!);

      return [...selectedFilters, ...options];
    }, []);
  }

  public defrost() {
    this.frozenFilter = [];
  }

  public resetFilters() {
    this.resetAllFilters({ force: true });
    this.frozenFilter.forEach((filter) => this.publisher.next(filter, true));
    this.defrost();
  }

  public setFilters(response: PropertyFiltersQueryQuery) {
    const filters = response.propertySearch.sortAndFilter.options?.sortAndFilter || [];

    this.filtersMap = new Map<string, Filter>(
      filters
        .filter((filter) => filter?.options && filter?.label && validFilterNames.includes(filter?.name))
        .map((filter) => {
          const { name, label, options, subLabel, min, max, step } = filter;
          const filterOptions = [...options];

          if (min !== null && max !== null && !options.length) {
            filterOptions.push({
              isSelected: false,
              optionValue: [min, max].join(),
              filterCategory: null,
              range: {
                min,
                max,
              },
            });
          }

          const optionsMap = new Map<string, Options>(
            filterOptions.map((option) => {
              const isDefault =
                option.optionValue === this.originalRegionId ||
                option.optionValue === "" ||
                option.optionValue === DEFAULT_SORT;

              return [option.optionValue!, { ...option, isDefault, order: 0, disabled: false }];
            })
          );

          return [name, { name, label: label!, options: optionsMap, min, max, step, subLabel }];
        })
    );
    this.syncFilters();
  }

  public syncFilters() {
    this.filtersMap.forEach((filter) => {
      filter.options.forEach((option) => {
        this.publisher.subscribe(option.selectedLabel!, (value: boolean) => {
          option.isSelected = value;
          this.order += value ? 1 : -1;
          option.order = this.order;
          if (!value) option.disabled = false;
        });
      });
    });
  }

  public setRegion(region: string) {
    this.originalRegionId = String(region);
  }

  public setPricePill(pricePill?: Options | null) {
    if (pricePill?.selectedLabel === this.pricePill?.selectedLabel) return;

    this.pricePill = pricePill && {
      ...pricePill,
      order: ++this.order,
    };
  }

  public setHasSort(value: boolean) {
    this.hasSort = value;
  }

  public setHasInteractedWithFilters() {
    this.hasInteractedWithFilters = true;
  }

  public getListOfFilters() {
    return Array.from(this.filtersMap.values()).filter((filter) => filter.name !== "sort");
  }

  public getPopularFilters() {
    return this.getOptions("popularFilter");
  }

  public isPopularFilterAvailableInFilters(popularFilterName: string) {
    const filter = this.filtersMap.get(popularFilterName);

    if (!filter) return false;
    return true;
  }

  public getSort({ force } = { force: false }) {
    if (force || this.hasSort) return this.filtersMap.get("sort")!;

    return null;
  }

  public getOptions(filterName: string): Options[] {
    const filter = this.filtersMap.get(filterName);

    if (!filter) return [];

    return Array.from(filter.options.values());
  }

  public getFreeCancellationFilter() {
    const paymentType = this.filtersMap.get("paymentType");
    const freeCancellation = paymentType?.options.get("FREE_CANCELLATION");

    return freeCancellation;
  }

  public isEmpty() {
    return this.filtersMap.size === 0;
  }

  public getNumberOfSelectedFilters() {
    return Array.from(this.filtersMap.values()).reduce((count, filter) => {
      return count + this.getNumberOfSelectedFiltersPerCategory(filter.name);
    }, 0);
  }

  public getNumberOfSelectedFiltersPerCategory(filterName: string) {
    const filters = this.filtersMap.get(filterName);
    const options = Array.from(filters?.options.values() || []);

    return options.filter(this.isOptionSelected.bind(this)).length;
  }

  private isOptionSelected(option: Options) {
    const isPopularFilter = option.filterCategory;
    return option.isSelected && !isPopularFilter && !option.isDefault;
  }

  public getNumberOfSelectedExpandedFilters() {
    return Array.from(this.filtersMap.values()).reduce((count, filter) => {
      return count + this.getNumberOfSelectedExpandedFiltersPerCategory(filter.name);
    }, 0);
  }

  public getNumberOfSelectedExpandedFiltersPerCategory(filterName: string) {
    const filters = this.filtersMap.get(filterName);
    const options = Array.from(filters?.options.values() || []);

    return options.filter(this.isExpandedOptionSelected.bind(this)).length;
  }

  private isExpandedOptionSelected(option: Options) {
    const isPopularFilter = option.filterCategory;
    const isExistingFilterAvailable = this.isPopularFilterAvailableInFilters(isPopularFilter ?? "");
    if (isExistingFilterAvailable) {
      return option.isSelected && !isPopularFilter && !option.isDefault;
    }

    return option.isSelected && !option.isDefault;
  }

  public isSelected(filterName: string, optionName: string) {
    return this.filtersMap.get(filterName)?.options.get(optionName)?.isSelected;
  }

  public setFilterRange(filterName: string, optionName: string, range: FilterOptionRange) {
    if (
      this.filtersMap.get(filterName)?.min == null ||
      this.filtersMap.get(filterName)?.max == null ||
      !this.filtersMap.get(filterName)?.options.get(optionName) ||
      !range
    )
      return;

    const { min, max } = this.filtersMap.get(filterName)!;
    const option = this.filtersMap.get(filterName)!.options.get(optionName)!;
    const value = (range.min || 0) > min! || (range.max || 0) < max!;
    const optionValue = [range.min, range.max].join();

    option.range = range;
    option.optionValue = optionValue;

    this.filtersMap.get(filterName)?.options.delete(optionName);
    this.filtersMap.get(filterName)?.options.set(optionValue, option);
    this.setFilterStatus(filterName, optionValue, value);
  }

  public toggleSelection = (filterName: string, optionName: string) => {
    if (!this.filtersMap.get(filterName)?.options.get(optionName)) return;

    const option = this.filtersMap.get(filterName)!.options.get(optionName)!;
    const value = !option.isSelected;

    this.setFilterStatus(filterName, optionName, value);

    return this.originalRegionId;
  };

  public setFilterStatus = (filterName: string, optionName: string, value: boolean) => {
    const option = this.filtersMap.get(filterName)!.options.get(optionName)!;
    const groupName = option.filterCategory || filterName;

    if (
      PROPERTY_FILTERS_COMPONENT_GROUP_MAP[groupName] === RadioButtonGroup ||
      PROPERTY_FILTERS_COMPONENT_GROUP_MAP[groupName] === SelectGroup
    ) {
      if (value) {
        this.clearFilters(groupName);
      } else {
        this.resetPerCategory(groupName);
      }
    }

    this.publisher.next(option.selectedLabel!, value);
  };

  public setDisabled = (filterName: string, optionValue: string, value: boolean) => {
    const option = this.filtersMap.get(filterName)!.options.get(optionValue)!;
    option.disabled = value;
  };

  public resetPerCategory(groupName: string) {
    this.filtersMap.get(groupName)!.options.forEach((option) => {
      const value = option.isDefault; // set true or false according to default config

      if (value !== option.isSelected) this.publisher.next(option.selectedLabel!, value);
    });
  }

  public resetAllFilters({ force } = { force: false }) {
    Array.from(this.filtersMap.values()).forEach((filter) =>
      filter.options.forEach((option) => {
        if (option.isSelected) {
          this.publisher.next(option.selectedLabel!, false);
        }

        if (force) return;

        if (option.isDefault) {
          this.publisher.next(option.selectedLabel!, true);
        }
      })
    );
  }

  public clearFilters(filterName: string) {
    if (!this.filtersMap.get(filterName)?.options) {
      return;
    }

    this.filtersMap.get(filterName)!.options.forEach((option) => {
      if (option.isSelected) this.publisher.next(option.selectedLabel!, false);
    });
  }

  public buildLinkToSRP(buildLinkToSRPParameters: {
    context?: ExtendedContextStore;
    daysOffset?: number;
    checkForPopularFilterInFilters?: boolean;
  }) {
    const link = new HotelSearchLinkBuilder()
      .withStayLength()
      .withDaysInFuture(buildLinkToSRPParameters.daysOffset)
      .withRegionId(this.originalRegionId)
      .withExpandForm();

    // Add filters
    Array.from(this.filtersMap.values()).forEach((filter) => {
      Array.from(filter.options.values())
        .filter((option) => option.isSelected)
        .forEach((option) => {
          // We dont want to get popular filters since they are propagated

          if (buildLinkToSRPParameters.checkForPopularFilterInFilters) {
            const popularFilterName = option.filterCategory;
            const isExistingFilterAvailable = this.isPopularFilterAvailableInFilters(popularFilterName ?? "");

            if (!isExistingFilterAvailable && option.filterCategory) {
              link.withFilter(option.filterCategory, option.optionValue || "");
            } else {
              link.withFilter(filter.name, option.optionValue || "");
            }
          } else {
            if (option.filterCategory) return;

            link.withFilter(filter.name, option.optionValue || "");
          }
        });
    });

    return link.build(buildLinkToSRPParameters.context);
  }

  /**
   * @deprecated
   *
   * Remove when `LodgingDestinationBexApiWrapper` is updated to use the non-deprecated
   * graphql inputs.
   */
  public getFiltersInput(): PropertySearchFiltersInput {
    const getValue = (name: string, value?: string | null) => {
      if (!value) return null;
      if (name === "starList") return Number(value);

      return value;
    };

    return this.getListOfFilters().reduce<{ [key: string]: any | any[] }>((selectedFilters, filter) => {
      filter.options.forEach((option) => {
        /* Exclude not selected and popular filters options*/
        if (!option.isSelected || option.filterCategory) return;

        const bexApiName = getFilterName(filter.name);
        const isRadio = PROPERTY_FILTERS_COMPONENT_GROUP_MAP[filter.name] === RadioButtonGroup;

        if (option.range) {
          if (!selectedFilters[bexApiName]) {
            selectedFilters[bexApiName] = { min: option.range.min, max: option.range.max };

            return;
          }

          const prev = selectedFilters[bexApiName];
          const hasMax = Boolean(option.range.max);
          const max = option.range.max! > prev.max! ? option.range.max : prev.max;

          selectedFilters[bexApiName] = {
            min: option.range.min! < prev.min! ? option.range.min : prev.min,
            max: hasMax ? max : null,
          };
        } else if (isRadio) {
          selectedFilters[bexApiName] = getValue(bexApiName, option.optionValue);
        } else {
          const prev = selectedFilters[bexApiName] || [];
          selectedFilters[bexApiName] = [getValue(bexApiName, option.optionValue), ...prev];
        }
      });

      return selectedFilters;
    }, {});
  }

  public getFilterSearchQueryVariables(limit: number = 10): Omit<PropertyFilterSearchQueryQueryVariables, "context"> {
    const selections: SelectedValueInput[] = [];
    const counts: NumberValueInput[] = [];
    const ranges: RangeValueInput[] = [];

    // Build the filter values
    this.getListOfFilters().forEach((filter) => {
      filter.options.forEach((option) => {
        /* Exclude not selected and popular filters options*/
        if (!option.isSelected || option.filterCategory) return;

        const bexApiName = getFilterName(filter.name);

        if (option.range) {
          const existingRange = ranges.find((range) => range.id === bexApiName);
          if (!existingRange) {
            ranges.push({
              id: bexApiName,
              min: option.range.min!,
              max: option.range.max!,
            });

            return;
          }

          const hasMax = Boolean(option.range.max);
          const max = option.range.max! > existingRange.max ? option.range.max! : existingRange.max;

          existingRange.min = option.range.min! < existingRange.min ? option.range.min! : existingRange.min;
          existingRange.max = hasMax ? max : null!;
        } else {
          selections.push({
            id: bexApiName,
            value: option.optionValue || "",
          });
        }
      });
    });

    selections.push({
      id: "sort",
      value: this.getOptions("sort").find((option) => option.isSelected)?.optionValue || DEFAULT_SORT,
    });

    counts.push({
      id: "resultsSize",
      value: limit,
    });

    return {
      destination: {
        regionId: this.originalRegionId,
      },
      criteria: {
        primary: {
          destination: {
            regionId: this.originalRegionId,
          },
          dateRange: null,
          rooms: [],
        },
        secondary: {
          counts,
          selections,
          ranges,
        },
      },
    };
  }
}
