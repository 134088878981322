import { defaultViewSizes } from "@shared-ui/viewport-context";
import { formatText } from "bernie-l10n";
import { TypeaheadSelection } from "@egds/react-core/typeahead";

export const CURRENT_LOCATION_TYPE = "CURRENT_LOCATION";

/**
 * Reads the user's current location using the browser's API.
 * Returns a promise to a TypeaheadSelection object with the UitkTypeahead selection structure.
 */
export const getCurrentLocationSelection: () => Promise<TypeaheadSelection> = () => {
  if (!navigator.geolocation) {
    return Promise.reject(new Error("navigator.geolocation is unsupported"));
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) =>
        resolve({
          term: formatText("typeahead.currentLocation.available.item"),
          data: {
            type: CURRENT_LOCATION_TYPE,
            location: {
              lat: latitude.toFixed(5),
              long: longitude.toFixed(5),
            },
          },
        }),
      (error) => reject(error)
    );
  });
};

/**
 * The Current Location feature is only shown on small breakpoints on browsers which support it.
 */
export const isDefaultGeolocationSupported = () =>
  Boolean(window && "geolocation" in navigator && window.innerWidth < defaultViewSizes.MEDIUM);
